import React, { useState } from 'react';
import './App.css';
import TelegramAuth from './components/TelegramAuth';
import { TonConnectButton } from '@tonconnect/ui-react';
import IndexCard from './components/IndexCard';
import OrderFormModal from './components/OrderFormModal';
import { Button } from '@mui/material'; // Import the Button component from Material-UI
import TradingViewWidget from './components/TradingViewWidget';

const indices = [
  {
    "Name": "S&P 500",
    "Code": "SPX",
    "Exchange": "New York Stock Exchange",
    "Country": "United States",
    "CountryCode": "US",
    "FlagIconURL": "https://hatscripts.github.io/circle-flags/flags/us.svg"
  },
  {
    "Name": "Nikkei 225",
    "Code": "N225",
    "Exchange": "Tokyo Stock Exchange",
    "Country": "Japan",
    "CountryCode": "JP",
    "FlagIconURL": "https://hatscripts.github.io/circle-flags/flags/jp.svg"
  },
  {
    "Name": "SSE Composite Index",
    "Code": "000001.SS",
    "Exchange": "Shanghai Stock Exchange",
    "Country": "China",
    "CountryCode": "CN",
    "FlagIconURL": "https://hatscripts.github.io/circle-flags/flags/cn.svg"
  },
  {
    "Name": "AEX Index",
    "Code": "AEX",
    "Exchange": "Euronext",
    "Country": "Netherlands",
    "CountryCode": "NL",
    "FlagIconURL": "https://hatscripts.github.io/circle-flags/flags/nl.svg"
  },
  {
    "Name": "FTSE 100",
    "Code": "FTSE",
    "Exchange": "London Stock Exchange",
    "Country": "United Kingdom",
    "CountryCode": "GB",
    "FlagIconURL": "https://hatscripts.github.io/circle-flags/flags/gb.svg"
  },
  {
    "Name": "Hang Seng Index",
    "Code": "HSI",
    "Exchange": "Hong Kong Stock Exchange",
    "Country": "Hong Kong",
    "CountryCode": "HK",
    "FlagIconURL": "https://hatscripts.github.io/circle-flags/flags/hk.svg"
  },
  {
    "Name": "S&P/TSX Composite Index",
    "Code": "GSPTSE",
    "Exchange": "Toronto Stock Exchange",
    "Country": "Canada",
    "CountryCode": "CA",
    "FlagIconURL": "https://hatscripts.github.io/circle-flags/flags/ca.svg"
  },
  {
    "Name": "DAX",
    "Code": "GDAXI",
    "Exchange": "Frankfurt Stock Exchange",
    "Country": "Germany",
    "CountryCode": "DE",
    "FlagIconURL": "https://hatscripts.github.io/circle-flags/flags/de.svg"
  },
  {
    "Name": "S&P/ASX 200",
    "Code": "AXJO",
    "Exchange": "Australian Securities Exchange",
    "Country": "Australia",
    "CountryCode": "AU",
    "FlagIconURL": "https://hatscripts.github.io/circle-flags/flags/au.svg"
  },
  {
    "Name": "BSE Sensex",
    "Code": "BSESN",
    "Exchange": "Bombay Stock Exchange",
    "Country": "India",
    "CountryCode": "IN",
    "FlagIconURL": "https://hatscripts.github.io/circle-flags/flags/in.svg"
  }
];


const App = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [orderType, setOrderType] = useState('buy');

  const handleOpenModal = (type) => {
    setOrderType(type);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="App">
      <div className="header">
      <div className="telegram-connect-button">
        <TonConnectButton />
      </div>
      <h1>WorldX</h1>
      </div>
      {/* <div className="telegram">
        <TelegramAuth />
      </div> */}
      <div className="trading-area">
        <div className="index-cards-container">
          {indices.map((index, idx) => (
            <IndexCard key={idx} index={index} onOpenModal={handleOpenModal} />
          ))}
          <OrderFormModal open={modalOpen} handleClose={handleCloseModal} type={orderType} />
        </div>
        {/* <div className='trading-view-widget'>
          <TradingViewWidget />
        </div> */}
      </div>
    </div>
  );
};

export default App;
