import React from 'react';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import App from '../App';

const TonConnectWrapper = () => {
  return (
    <TonConnectUIProvider manifestUrl="https://raw.githubusercontent.com/ton-community/tutorials/main/03-client/test/public/tonconnect-manifest.json">
      <App />
    </TonConnectUIProvider>
  );
};

export default TonConnectWrapper;