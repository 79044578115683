import PropTypes from "prop-types";
import "./IndexCard.css";

const IndexCard = ({ index, className = "" }) => {
  return (
    <div className={`card ${className}`}>
      <div className="info">
        <img className="card-logo" loading="lazy" alt="" src={index.FlagIconURL} />
        <div className="div">
          <div className="ticker">{index.Code}</div>
          <div className="name">{index.Name}</div>
        </div>
      </div>
      {/* <div className="wrapper-group-2">
        <img
          className="wrapper-group-2-child"
          loading="lazy"
          alt=""
          src="/group-2.svg"
        />
      </div> */}
      <div className="market-info">
        <div className="price">$2,350</div>
        <div className="graph">
          {/* <img
            className="arrow-drop-up-black-24dp-1-1"
            loading="lazy"
            alt=""
            src="/arrow-drop-up-black-24dp-1-1.svg"
          /> */}
          <div className="change">2.35%</div>
        </div>
      </div>
    </div>
  );
};

IndexCard.propTypes = {
  className: PropTypes.string,
};

export default IndexCard;
