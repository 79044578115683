import React from 'react';
import { Modal, Box, Button, TextField, Typography } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%', // Use a percentage width for responsiveness
  maxWidth: '400px', // Set a maximum width
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const OrderFormModal = ({ open, handleClose, type }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {type === 'buy' ? 'Buy Order' : 'Sell Order'}
        </Typography>
        <TextField
          fullWidth
          label="Amount"
          margin="normal"
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Price"
          margin="normal"
          variant="outlined"
        />
        <Button
          fullWidth
          variant="contained"
          color={type === 'buy' ? 'primary' : 'secondary'}
          onClick={handleClose}
        >
          {type === 'buy' ? 'Buy' : 'Sell'}
        </Button>
      </Box>
    </Modal>
  );
};

export default OrderFormModal;
